@use 'styles/base/mixins';
@use 'styles/base/variables';

.overlay {
  display: none;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity variables.$transition-fast variables.$transition-timing-ease-in-out;
  background-color: rgba(variables.$gb_black, 0.75);

  @include mixins.screen-sm {
    display: block;
  }
}

:global(.mobilenav-open) {
  .overlay {
    opacity: 1;
    pointer-events: all;
  }
}
