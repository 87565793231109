@use 'styles/base/mixins';
@use 'styles/base/variables';

.noResultsWrapper {
  display: flex;
  flex-direction: column;
  padding: 5rem 0;
  background-color: variables.$gb_grey_050;
  font-size: 1.125rem;
  line-height: 1.5rem;

  @include mixins.screen-md {
    padding: 6rem 0;
  }

  p {
    font-weight: variables.$font-weight-medium;
    text-align: center;
    margin-bottom: 0;
    max-width: 33rem;
  }

  p,
  button {
    align-self: center;
  }

  button {
    margin-top: 2rem;
  }
}
