@use 'variables';

@font-face {
  font-display: swap; /* auto, swap, block, fallback, optional */
  font-family: 'AktivGrotesk';
  font-style: normal;
  font-weight: variables.$font-weight-bold;
  src:
    local('Aktiv Grotesk Bold'),
    url('#{variables.$gb_webfontPath}/AktivGroteskGeberit_W_Bd.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{variables.$gb_webfontPath}/AktivGroteskGeberit_W_Bd.woff')
      format('woff');
  /* Pretty Modern Browsers */
}

@font-face {
  font-display: swap; /* auto, swap, block, fallback, optional */
  font-family: 'AktivGrotesk';
  font-style: normal;
  font-weight: variables.$font-weight-medium;
  src:
    local('Aktiv Grotesk Medium'),
    url('#{variables.$gb_webfontPath}/AktivGroteskGeberit_W_Md.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{variables.$gb_webfontPath}/AktivGroteskGeberit_W_Md.woff')
      format('woff');
  /* Pretty Modern Browsers */
}

@font-face {
  font-display: swap; /* auto, swap, block, fallback, optional */
  font-family: 'AktivGrotesk';
  font-style: normal;
  font-weight: variables.$font-weight-regular;
  src:
    local('Aktiv Grotesk Regular'),
    url('#{variables.$gb_webfontPath}/AktivGroteskGeberit_W_Rg.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{variables.$gb_webfontPath}/AktivGroteskGeberit_W_Rg.woff')
      format('woff');
  /* Pretty Modern Browsers */
}

@font-face {
  font-display: swap; /* auto, swap, block, fallback, optional */
  font-family: 'AktivGrotesk';
  font-style: normal;
  font-weight: variables.$font-weight-light;
  src:
    local('Aktiv Grotesk Light'),
    url('#{variables.$gb_webfontPath}/AktivGroteskGeberit_W_Lt.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{variables.$gb_webfontPath}/AktivGroteskGeberit_W_Lt.woff')
      format('woff');
  /* Pretty Modern Browsers */
}

@font-face {
  font-display: swap; /* auto, swap, block, fallback, optional */
  font-family: 'Quickbrush';
  font-style: normal;
  font-style: 'regular';
  src:
    local('Quickbrush'),
    url('#{variables.$gb_nordicsFontPath}/Quickbrush.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{variables.$gb_nordicsFontPath}/Quickbrush.woff')
      format('woff');
  /* Pretty Modern Browsers */
}

@font-face {
  font-display: swap; /* auto, swap, block, fallback, optional */
  font-family: 'QuickbrushBaltics';
  font-style: normal;
  font-style: 'regular';
  src:
    local('Quickbrush Baltics'),
    url('#{variables.$gb_nordicsFontPath}/QuickbrushBaltics.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{variables.$gb_nordicsFontPath}/QuickbrushBaltics.woff')
      format('woff');
  /* Pretty Modern Browsers */
}
