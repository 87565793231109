@use 'styles/base/variables';

.orderWrapper {
  :global(th) {
    font-size: 1rem;
  }

  &#{&} :global(td) {
    vertical-align: middle;
  }

  :global(td > span) {
    font-size: 0.875rem;
  }

  :global(td input[type='number']) {
    border: 1px solid variables.$gb_grey_700;
    border-radius: 0;
    font-size: 0.875rem;
    height: 2.5rem;
    padding: 0 0.5rem;

    &:focus {
      border: 1px solid variables.$gb_black;
    }

    &:active {
      background-color: variables.$gb_grey_060;
    }
  }

  :global(td button .c-tpp-area),
  :global(table + button .c-tpp-area) {
    width: auto;
    height: auto;
  }
}
