.masterIcon {
  position: absolute;
  left: 20px;
  &::before {
    content: (url('/icons/masterPackage.svg'));
    position: relative;
    top: 10px;
    height: 32px;
    width: 32px;
    display: inline-block;
  }
}
