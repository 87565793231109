@use 'styles/base/mixins';
@use 'styles/base/variables';

.introTextWrapper {
  margin: variables.$spacing-gdds-section-mobile-small;

  @include mixins.screen-sm {
    margin: variables.$spacing-gdds-section-large;
  }

  &.lastSection {
    margin-bottom: 2rem;
  }

  &:not(.hasHeadline) {
    margin-top: 0.625rem;

    @include mixins.screen-sm {
      margin-top: 2rem;
    }
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0.625rem;

    @include mixins.screen-md {
      margin-bottom: 2rem;
    }
  }

  &.nordics,
  .xy & {
    margin: variables.$spacing-nordics-mobile;

    @include mixins.screen-sm {
      margin: variables.$spacing-nordics-desktop;
    }

    &.firstSection {
      margin-top: 2rem;

      @include mixins.screen-sm {
        margin-top: 3.25rem;
      }
    }

    .row {
      justify-content: center;
    }

    h2 {
      margin-bottom: 1rem;
    }

    .noMarginBottom h2 {
      margin-bottom: 0rem;
    }

    .text {
      &.withMargin {
        margin-top: 2rem;
      }
    }
  }

  &.default {
    margin-top: 2.5rem;
    margin-bottom: 3.125rem;

    @include mixins.screen-md {
      margin-bottom: 4.375rem;
    }
  }

  &.top-small {
    margin-top: 2rem;
  }

  &.bottom-small {
    margin-bottom: 2rem;
  }

  &.greyBackground {
    background-color: variables.$gb_grey_050;
    margin: 0;
    padding: variables.$spacing-gdds-section-mobile-small;

    @include mixins.screen-sm {
      padding: variables.$spacing-gdds-section-large;
    }

    &.lastSection {
      padding-bottom: 2rem;
    }

    &:not(.hasHeadline) {
      padding-top: 0.625rem;

      @include mixins.screen-sm {
        padding-top: 2rem;
      }
    }

    &.top-small {
      padding-top: 2rem;
    }

    &.bottom-small {
      padding-bottom: 2rem;
    }
  }

  &.whiteBackground {
    background-color: variables.$gb_white;
  }

  p,
  ul {
    max-width: unset;
    font-size: 1rem;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .intro {
    p,
    a:global(.gdds-element) {
      font-size: 1.25rem;
      line-height: 1.75rem;

      @include mixins.screen-sm {
        font-size: 1.375rem;
      }

      @include mixins.screen-md {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    sub,
    sup {
      font-size: 0.875rem;
    }
  }

  .text {
    font-size: 1rem;

    span > a:global(.gdds-element.noArrow) {
      vertical-align: baseline;

      & > span {
        padding-right: 0;
      }
    }

    &.withMargin {
      margin-top: 1.5rem;
    }

    a {
      cursor: pointer;
    }

    sub,
    sup {
      font-size: 0.75rem;
    }
  }

  :global(.twyford) & {
    h1 {
      font-weight: variables.$font-weight-bold !important; //necessary
    }
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
}

.left {
  display: flex;
  flex-direction: column;
}

.border {
  margin-top: 2.5rem;

  @include mixins.screen-sm {
    margin-top: 5rem;
  }
}
