.footer {
  margin-top: 4.875rem;

  a {
    cursor: pointer;

    &::before {
      content: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .dummyToTopButton {
    width: 3rem;
    height: 3rem;
    position: absolute;
    right: 2rem;
    top: -4rem;
  }

  li {
    padding-bottom: 0;
  }

  .gdds-element {
    margin-top: 0;
  }
}
