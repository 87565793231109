@use 'styles/base/variables';
@use 'styles/base/mixins';

.paragraph {
  margin-bottom: 0;

  :global(.xy) & {
    @include mixins.typography-body-1();
  }
}

:global(.xy) {
  :global(.c-page-headline) {
    margin-top: 0;
    margin-bottom: 0.625rem;
    @include mixins.screen-md {
      margin-bottom: 2rem;
    }
  }
}

.gddsOrdersAccordion {
  margin: 3rem 0;

  table {
    width: 100%;
    margin: 1rem 0;

    th {
      @include mixins.typography-body-2();
    }

    td {
      vertical-align: middle;
      @include mixins.typography-label-2();
    }

    tr:last-of-type td {
      border-bottom: none;
    }
  }

  :global(.pd-table__column__head) {
    @include mixins.typography-body-2();
    font-weight: variables.$font-weight-bold;
  }

  :global(.pd-table__column) {
    @include mixins.typography-label-2();
  }
}

.message {
  margin-top: 2rem;
}

.historyLoader {
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
}

.historyLoadmore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  button {
    width: auto;
  }
}

.inactive {
  color: variables.$gb_grey_400;
}

.orderHistory {
  display: block;
  max-width: 76.875rem;
  background: #fff;
  padding: 5.625rem 0;

  h4 {
    margin: 0;
  }

  :global(.c-accordion__title[aria-expanded='true']) {
    margin-bottom: 1.625rem;

    :local(.accordionTitle) {
      color: variables.$gb_black;

      small {
        color: variables.$gb_black;
        font-weight: 400;
        margin-left: 0.75rem;
      }
    }
  }

  :global(.c-accordion__body) {
    .pendingOrderDescription {
      font-size: 13px;
    }
  }

  :local(.accordionTitle) {
    color: var(--gb-brand-primary-light);

    .titleDate {
      margin-right: 0.75rem;
    }

    .pendingOrder {
      text-transform: none;
    }

    small {
      font-size: 0.9375rem;
      color: variables.$gb_grey_700;
      text-transform: none;
      margin-left: 0.75rem;
    }
  }

  :local(.table) {
    width: 100%;
    font-size: 0.8125rem;

    th {
      white-space: nowrap;
      font-size: 0.8125rem;

      &:last-of-type {
        text-align: center;
      }
    }

    tr:last-of-type td {
      border-bottom: none;
    }

    td {
      vertical-align: middle;
      text-align: center;

      img {
        width: 3.75rem;
      }

      &.product {
        width: 65%;
        text-align: left;
      }
    }
  }
}
