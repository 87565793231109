@use 'styles/base/mixins';
@use 'styles/base/variables';

.campusFilter {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.flyout {
  position: absolute;
  top: 2.5rem;
  left: 0;
  right: 0;
  background-color: variables.$gb_white;
  z-index: 1;

  > div:first-child {
    max-height: none;
  }

  & > div {
    padding-bottom: 3.25rem;
  }

  ul {
    // max 5 items with 42px height
    max-height: 13.125rem;
    overflow-y: auto;

    // add visible scrollbar to trackpads
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 0.8675rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.5rem;
      border: 2px solid variables.$gb_white;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-track {
      background-color: variables.$gb_white;
      border-radius: 0.5rem;
    }

    li > div {
      flex: 1;
    }
  }
}

.buttonWrapper {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0.5rem 0.5rem 0 0.5rem;

  button {
    width: 100%;

    :global(.c-tpp-area) {
      text-align: center;
    }
  }
}

.filterWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @include mixins.screen-sm {
    max-width: 33.375rem;
  }

  @include mixins.screen-md {
    max-width: 46.5rem;
  }
}

.filterItem {
  margin-bottom: 1rem;
  width: 100%;
  position: relative;

  &:not(.filterItemReset) > button {
    min-width: 100%;
    width: 100%;
    padding-left: 0.5rem;
    justify-content: space-between;
    border: 1px solid variables.$gb_gdds_cl10b;

    > .dropdownTitleWrapper {
      text-align: left;
      width: calc(100% - 2.5rem);

      @include mixins.screen-sm {
        width: calc(15.9375rem - 3.5rem);
      }

      @include mixins.screen-md {
        width: calc(15.5rem - 3.5rem);
      }
    }
  }

  .dropdownTitle,
  :global(.c-tpp-area) {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    height: auto;
    text-align: left;
  }

  :global(.c-tpp-area) {
    margin-top: 1px;
  }

  li {
    padding: 0;
  }

  @include mixins.only-screen-md {
    margin-right: 1.5rem;
    flex-basis: 33%;
    display: flex;

    & > button {
      width: 15.9375rem;
      min-width: 15.9375rem;
    }

    &:nth-child(1n) {
      justify-content: flex-end;
    }

    &:nth-child(even) {
      margin-right: 0;
      justify-content: flex-start;
    }
  }

  @include mixins.screen-md {
    width: 14.5rem;
    margin-right: 1.5rem;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &.filterItemReset {
    display: flex;
    justify-content: flex-end;

    > button {
      width: auto;
      min-width: auto;
    }

    @include mixins.screen-sm {
      justify-content: flex-start;
    }
  }
}

.inactive {
  > button {
    color: rgba(0, 0, 0, 0.36);
  }
}
