@use 'styles/base/mixins';
@use 'styles/base/variables';

.box {
  position: relative;

  > button::after {
    @include mixins.web20-icon('chevron-small-down');
    margin-left: 0.25rem;
  }

  &.open {
    > button::after {
      @include mixins.web20-icon('chevron-small-up');
    }
  }
}

.wrapper {
  list-style-type: none;
  position: absolute;
  top: 1.625rem;
  right: 0;
  z-index: 60;
  background: variables.$gb_white;
  box-shadow: variables.$default-box-shadow;
  margin: 0;
  padding: 0;

  &::before {
    position: absolute;
    right: 0;
    transform: translateX(-50%);
    content: ' ';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.625rem 0.625rem 0.625rem;
    border-color: transparent transparent variables.$gb_white transparent;
    top: -0.625rem;
    z-index: 65;
  }

  &::after {
    position: absolute;
    right: -0.25rem;
    transform: translateX(-50%);
    content: ' ';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.75rem 0.675rem 0.75rem;
    border-color: transparent transparent variables.$gb_grey_100 transparent;
    top: -0.675rem;
  }

  li {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0.25rem;

    &:last-of-type {
      padding-bottom: 0.25rem;
    }

    button {
      white-space: nowrap;
      width: 100%;
      justify-content: flex-start;
      padding-left: 0.5rem;
    }
  }
}
