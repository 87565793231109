@use 'styles/base/mixins';
@use 'styles/base/variables';

.mediaTileWrapper {
  border: 1px solid variables.$gb_gdds_cl09;
  margin-bottom: 1.25rem;
  margin-top: 0.25rem;
  padding: 1.25rem;
  position: relative;

  @include mixins.screen-lg {
    cursor: pointer;
    height: 10.875rem;
    margin-bottom: 2rem;

    &:hover {
      background-color: variables.$gb_gdds_cl03;

      .mediaTileButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 0;
        text-align: center;
        top: calc(50% - 20px);
      }

      .imageTextWrapper {
        display: none;
      }

      .mediaDownloadIndicator {
        display: none;
      }
    }
  }
}

.mediaDownloadIndicator {
  @include mixins.screen-lg {
    position: absolute;
    bottom: 0.625rem;
    right: 0.625rem;
  }
}

.imageTextWrapper {
  display: flex;
}

.mediaTileButtons {
  display: flex;
  flex-direction: row;
  margin-top: 1.875rem;

  @include mixins.screen-md {
    margin-bottom: 1.875rem;
  }

  @include mixins.screen-lg {
    display: none;
    margin-bottom: 0;
    position: relative;
  }

  button {
    margin-right: 1rem;
  }

  a {
    margin-right: 1.5rem;
  }

  // hover state of buttons
  a,
  button {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 0.875rem;

    @include mixins.screen-lg {
      font-size: 1rem;
    }
  }
}

.buttonDownload {
  display: flex;
  align-items: center;
}

.mediaTileTextArea {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.mediaTileDate {
  color: variables.$gb_gdds_cl13;
  font-size: 0.875rem;
}

.mediaTileFileInfo {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  font-size: 0.875rem;
  color: variables.$gb_grey_650;
}

.mediaTileFileIcon {
  margin-right: 0.5rem;
}

.mediaTileImage {
  height: 7rem;
  width: 5.25rem;
  object-fit: contain;
}
