@use 'styles/base/mixins';
@use 'styles/base/variables';

.label {
  font-size: 0.75rem;
  line-height: 1rem;
  color: variables.$gb_grey_650;
  padding-bottom: 0.25rem;
  display: block;
}

.nordUploadFiles {
  margin-top: 1rem;
}

.nordUploadError {
  margin-top: 1rem;
}

.uploadbutton {
  > button {
    min-width: 100%;

    @include mixins.screen-sm {
      min-width: auto;
    }
  }
}
