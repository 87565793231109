@use 'styles/base/mixins';
@use 'styles/base/variables';

.maintenancePage {
  min-height: 100%;

  @include mixins.screen-sm {
    background: variables.$gb_grey_060 no-repeat center;
    background-size: cover;
    height: 100%;
    padding: 3.125rem 0 9.375rem 0;
  }
}

.maintenanceLayer {
  background-color: variables.$gb_white;
  padding: 1.5625rem 1.5625rem variables.$spacing-m 1.5625rem;
  margin: auto;

  @include mixins.screen-md {
    padding: variables.$spacing-xl 2.8125rem 2.8125rem 2.8125rem;
  }

  h2 {
    margin: 0 0 3.125rem;
  }
}

.information,
.downTimeTitle {
  font-size: 1rem;
}

.information {
  margin-top: 0.9375rem;
  margin-bottom: 2.1875rem;
}

.downtimeWrapper {
  margin-bottom: 0.9375rem;
  display: flex;

  span {
    font-size: 0.875rem;
  }

  .startDate,
  .endDate {
    display: flex;
    flex-direction: column;
  }

  .hyphen {
    margin: 0 1.25rem;
  }

  b,
  .hyphen {
    color: var(--gb-brand-primary);
    font-size: 1.375rem;
  }
}
