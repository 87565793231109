@use 'styles/base/mixins';
@use 'styles/base/variables';

.inTileContainer {
  position: absolute;
  top: 0;
}

.consentContainer {
  width: 100%;
  background-color: variables.$gb_grey_800;
  height: inherit;

  @include mixins.screen-sm {
    aspect-ratio: 16 / 9;
  }

  color: variables.$gb_white;
  display: flex;
  box-sizing: border-box;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: variables.$spacing-m variables.$spacing-s;

  h3 {
    margin-bottom: 0.75rem !important;
    text-transform: none;
    color: variables.$gb_white;
  }

  div {
    color: variables.$gb_white !important;
    margin-top: 0.75rem;
    margin-bottom: 1.375rem;

    p {
      color: variables.$gb_white !important;
      margin-bottom: 0;
    }

    & a {
      color: variables.$gb_white;
      display: inline !important;
      text-decoration: underline;
      padding: 0 !important;
      white-space: nowrap;

      & > span:first-child {
        padding-right: 0 !important;
      }

      &::before {
        content: none;
      }
    }
  }
}

.fullWidthHeight {
  width: 100%;
  height: 100%;
}
