@use 'styles/base/mixins';
@use 'styles/base/variables';

.courseTeaser {
  background: variables.$gb_grey_050;
  margin-bottom: 2.5rem;
  padding: 2.5rem 1.5rem 2rem;
  position: relative;

  @include mixins.screen-sm {
    margin-bottom: 1.5rem;
    margin-left: 1.563rem;
    padding: 1.75rem 1.5rem 2rem 4.5rem;
  }

  .courseHeadline {
    max-width: 47rem;

    h4 {
      margin-bottom: 16px;
      @include mixins.line-clamp(3);

      @include mixins.screen-sm {
        @include mixins.line-clamp(2);
      }

      @include mixins.screen-md {
        @include mixins.line-clamp(1);
      }
    }
  }

  .courseTeaserBodyWrapper {
    display: flex;
    justify-content: space-between;
  }

  .courseTeaserBody {
    border-bottom: variables.$gb_grey_150 1px solid;
    padding-bottom: 1.5rem;
    width: 100%;

    @include mixins.screen-md {
      margin-right: 2.5rem;
    }

    .type {
      font-size: 0.75rem;
      margin-bottom: 0.25rem;
    }
  }

  .catalogpage,
  .landingpage {
    max-width: 47rem;
  }

  .courseIcons {
    height: 3rem;
    position: absolute;
    top: -1.5rem;
    width: 3rem;

    @include mixins.screen-sm {
      left: -1.5rem;
      top: 1.25rem;
    }

    &.webinar {
      background-color: variables.$gb_bgcolor_webinar;
    }

    &.elearning {
      background-color: variables.$gb_bgcolor_elearning;
    }

    &.webcast {
      background-color: variables.$gb_bgcolor_webcast;
    }

    &.event {
      background-color: variables.$gb_bgcolor_event;
    }

    &.seminar {
      background-color: variables.$gb_bgcolor_seminar;
    }

    span {
      display: flex;
      justify-content: center;
      position: relative;
    }

    i {
      font-size: 1.5rem;
      line-height: 1.8;
    }
  }
  .expirationDateWrapper {
    color: variables.$gb_textcolor_expiration_date;
    line-height: 20px;
    text-align: right;
    margin-top: 24px;
    width: 100%;

    @include mixins.screen-sm {
      position: relative;
      right: -2rem;
    }

    @include mixins.screen-md {
      margin-top: 0;
      right: 2rem;
      position: absolute;
      width: inherit;
    }

    .expirationDateInner {
      font-weight: variables.$font-weight-bold;
      justify-content: right;

      @include mixins.screen-md {
        display: flex;
        flex-direction: row;
      }

      .icon {
        font-size: 16px;
        margin-right: 0.5rem;
      }

      span {
        margin: 0 6px;

        @include mixins.screen-md {
          display: flex;
          margin-right: 0;
        }
      }
    }
  }

  h4 {
    text-transform: none;
  }

  .text {
    color: variables.$gb_grey_700;
    max-width: 47rem;
    position: relative;

    @include mixins.line-clamp(5);

    @include mixins.screen-sm {
      @include mixins.line-clamp(3);
    }

    @include mixins.screen-md {
      @include mixins.line-clamp(2);
    }
  }

  .courseInformation {
    position: relative;
  }
}

.courseInformationItems {
  margin-top: 0.125rem;
  margin-bottom: 1.375rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include mixins.screen-sm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  > div {
    margin-bottom: 0;

    @include mixins.screen-sm {
      flex-basis: 50%;
    }
  }
}

.buttonDefaultContainer {
  display: flex;
  margin-top: 24px;

  @include mixins.screen-sm {
    margin-top: inherit;
    justify-content: flex-end;
  }

  .buttonDetails {
    min-width: 100%;

    @include mixins.screen-sm {
      min-width: 6.25rem;
    }
  }
}

.item {
  display: inline-flex;
  margin-top: 1.5rem;
  position: relative;
  flex-basis: 100%;

  @include mixins.screen-sm {
    flex-basis: 50%;
  }

  @include mixins.screen-md {
    flex-basis: 33%;
  }

  span b {
    display: flex;

    span {
      min-height: 20px;
    }
  }

  .capacityGreen {
    color: variables.$gb_capacitycolor_green;
    font-weight: bold;
  }

  .capacityOrange {
    color: variables.$gb_capacitycolor_orange;
    font-weight: bold;
  }

  .icon {
    margin-right: 0.5rem;
    margin-top: 2px;
    font-size: 1rem;
  }

  :global(.cost-per-person) {
    color: var(--gb-brand-primary-dark);
    font-weight: variables.$font-weight-medium;
    position: relative;
    cursor: pointer;
  }

  .itemContent {
    display: flex;
    flex-direction: row;
    position: relative;

    span:first-of-type {
      display: flex;
      margin-right: 15px;

      &::after {
        content: ' - ';
        margin-left: 15px;
      }
    }
  }
}

.popularFlag {
  background: variables.$gb_gdds_cl17;
  border-radius: 0.125rem;
  margin-left: 0.5rem;
  display: inline-block;
  padding: 0.125rem 0.375rem;
  font-size: 0.6875rem;
  line-height: 1rem;
  color: variables.$gb_white;
}
