@use 'styles/base/mixins';
@use 'styles/base/variables';

.lightboxWrapper {
  :global(.c-cart-orders__table *),
  :global(.c-cart-downloads td),
  :global(.c-cart-downloads span),
  :global(.c-cart-downloads button) {
    font-size: 1rem;
  }

  table + h4 + div {
    padding: 0;
  }

  form {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &#{&} .lightbox__headline {
    & h3 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 2rem;
      max-width: calc(100% - 3.25rem);

      @include mixins.screen-md {
        margin-bottom: 2.5rem;
        font-size: 1.5rem;
        line-height: 2rem;
        max-width: 36.75rem;
      }
    }

    h4 {
      margin-bottom: 1.5rem;
      font-size: 1.125rem;
      line-height: 1.5rem;

      > span {
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }
  }

  & .orders {
    padding: 0 0.5rem;

    @include mixins.screen-md {
      padding: 0;
    }
  }

  > div:first-child {
    align-items: center;
    padding-top: 0;
    z-index: 99;

    > div:first-child {
      height: unset;
    }
  }

  h6 {
    font-size: 1.5rem !important;
  }

  div[type='custom'] {
    max-height: 80vh;
    overflow: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}
