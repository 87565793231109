@use 'sass:math';
@use 'styles/base/mixins';
@use 'styles/base/variables';

$gutter: 1.875rem;
$gutterGdds: 1.5rem;

.contactModule {
  margin-bottom: 2rem;

  &.hasLocationId {
    @include mixins.screen-md {
      padding: 0 13.6875rem;
    }

    @include mixins.screen-sm {
      .contactModuleCopy {
        max-width: 74%;
      }
    }
  }

  // custom slick dot nav
  :global(.slick-dots) {
    line-height: 0;
    font-size: 0;
    text-align: center;
    padding: 0;
    margin: 1.875rem 0 0 0;

    li,
    button {
      line-height: 1;
      width: 1.875rem;
      height: 0.1875rem;
    }

    button {
      text-indent: -99999px;
    }

    li {
      overflow: hidden;
      display: inline-block;
      padding: 0;
      margin: 0 0.3125rem;
      background-color: variables.$gb_grey_200;

      &:global(.slick-active) button {
        background-color: var(--gb-brand-primary);
      }
    }
  }

  // slick slider styles
  :global(.slick-list) {
    margin: 0 math.div(-$gutter, 2);
  }

  :global(.slick-slide) {
    padding: 0 math.div($gutter, 2);
  }

  // GDDS Variant
  :global(.contactModule--gdds) {
    :global(.slick-dots) {
      margin: 2rem 0 0 0;
    }

    :global(.slick-list) {
      margin: 0 math.div(-$gutterGdds, 2);
    }

    :global(.slick-slide) {
      padding: 0 math.div($gutterGdds, 2);
    }
  }

  :global(.slick-track) {
    display: flex;
    :global(.slick-slide) {
      height: inherit;
      > div {
        height: 100%;
        > div {
          height: 100%;
        }
      }
    }
  }
}

.contactModuleHeadline {
  font-weight: variables.$font-weight-bold;
  font-size: 1.75rem;
  line-height: 1.28571428571;
  margin-bottom: 1.25rem;

  @include mixins.screen-md {
    font-size: 2.125rem;
  }

  // GDDS Variant
  :global(.contactModule--gdds) & {
    font-size: 1.625rem;
    line-height: 1.23076923077;

    @include mixins.screen-md {
      font-size: 1.75rem;
      line-height: 1.28571428571;
    }

    @include mixins.screen-lg {
      font-size: 2rem;
      line-height: 1.25;
    }
  }
}

.contactModuleHeadlineComp {
  margin-bottom: 2.5rem;
}

.contactModuleHeadlineText {
  margin-top: 0.625rem;

  @include mixins.screen-md {
    margin-top: 2rem;
  }
}

.contactModuleCopy {
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 2.8125rem;

  @include mixins.screen-sm {
    max-width: 37.1875rem;
  }

  @include mixins.screen-md {
    max-width: calc(50% - #{$gutter} / 2);

    // GDDS Variant
    :global(.contactModule--gdds) & {
      max-width: calc(50% - #{$gutterGdds} / 2);
    }
  }

  // GDDS Variant
  :global(.contactModule--gdds) & {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 2.5rem;
  }
}

.contactModuleItems {
  display: flex;
  justify-content: space-between;

  // special style in slider context
  :global(.is-slider) & {
    display: block;
  }
}

.contactModuleFurther {
  display: flex;
  justify-content: center;
  margin-top: 1.875rem;

  a[type='button'] {
    appearance: none;
    text-decoration: none;
  }

  :global(.contactModule--gdds) & {
    margin-top: 2rem;
  }
}
